import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import { responsiveScale } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import CardFrame from '../primitives/CardFrame'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'

import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps & {
  title: ReactNode
  content: ReactNode
  image: IGatsbyImageData
  imageAlign?: 'left' | 'right'
  imageFrame?: boolean
}

const MosaicBlock = ({
  title,
  content,
  image,
  imageAlign = 'left',
  imageFrame = true,
  paddingTop = responsiveScale(64),
  paddingBottom = responsiveScale(64),
}: Props) => {
  const gatsbyImage = <GatsbyImage image={image} alt="" />
  return (
    <Block>
      <Row
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        space={{ xs: 0, sm: 32 }}
        width="100%"
        flexDirection={{
          xs: 'column',
          sm: imageAlign === 'right' ? 'row-reverse' : 'row',
        }}
      >
        <Box flex={0} alignContent="center" flexBasis={{ xs: 'none', sm: 652 }}>
          {imageFrame ? (
            <CardFrame width="100%">{gatsbyImage}</CardFrame>
          ) : (
            gatsbyImage
          )}
        </Box>
        <Stack
          space={24}
          paddingTop={{ xs: 32, sm: 48 }}
          paddingX={32}
          flex={1}
          flexBasis={{ xs: 'none', sm: 500 }}
        >
          <Box.h2 font="h2" maxWidth="mosaic">
            {title}
          </Box.h2>
          <Box font="large" maxWidth="mosaicText">
            {content}
          </Box>
        </Stack>
      </Row>
    </Block>
  )
}

export default MosaicBlock
