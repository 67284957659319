import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import AntiPatternsIcon from '../../assets/images/product/blurb-icons/anti-patterns.png'
import ZoomIcon from '../../assets/images/product/blurb-icons/zoom.png'
import MetaImage from '../../assets/images/product/work-log/work-log-meta.png'
import tag from '../../assets/images/tags/work-log-tag.svg'
import Layout from '../../components/Layout'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import FooterBlock from '../../components/blocks/FooterBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import ToolsBlock from '../../components/blocks/ToolsBlock'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/work-log/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/work-log/anti-patterns.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1440)
      }
    }
    lead2: file(
      relativePath: { eq: "images/product/work-log/high-level-work-log.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1440)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/product/work-log/issue-pop-up.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/product/work-log/review-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

const WorkLogPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Work log"
      variant="dark"
      isNew
      description="Work log allows you to see all the work that’s happening in the engineering team. Use it to analyze individual issues or get a bird’s eye view of the past year."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Analyze activity patterns and&nbsp;unblock the flow</>}
        content="Work log brings together data from all the tools where engineering work happens. Get a full picture of work in progress and regain focus on what matters."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <LeadBlock
        heading="Get the right things done"
        content="Software development doesn’t have to be a black box. Work log allows you to quickly see where your team’s work gets stuck — so you can get unstuck just as quickly."
        alt="Visualize work in work log"
        image={getImage(data.lead)}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="See all work at a glance"
            text="Combine issue tracker insights with data from your codebase."
            icon={AllWorkIcon}
          />,
          <Blurb
            key="2"
            title="Analyze activity anti-patterns"
            text="Quickly spot anti-patterns like siloing, interruptions, or too many projects in flight."
            icon={AntiPatternsIcon}
          />,
          <Blurb
            key="3"
            title="Zoom in and out"
            text="See all the work you’ve done in the past year or look at a two-week period at a time."
            icon={ZoomIcon}
          />,
        ]}
      />
      <LeadBlock
        heading="Get a bird’s eye view into completed projects"
        content="Zoom out to see all the projects your team has been working on over the past year. A steady staircase-like pattern communicates that your team is able to finish existing projects before they start new ones."
        alt="Visualize months of work in high-level work log"
        image={getImage(data.lead2)}
      />
      <MosaicBlock
        title="Start better conversations with accurate data"
        content="Drill into stories or epics to see how they progressed over time. Use detailed insights like flow efficiency and scope creep in your retrospectives to improve planning and collaboration."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={<>Improve collaboration in&nbsp;the&nbsp;team</>}
        content="Quickly see how work is distributed between team members. Spot opportunities to increase collaboration on certain types of work or split code reviews more evenly."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <ToolsBlock />
      <QuoteBlock person="tiina" />
      <FooterBlock productPages={['investmentBalance', 'workingAgreements']} />
    </Layout>
  )
}

export default WorkLogPage
