import React from 'react'
import ToolsImage from '../../assets/images/product/tools.svg'
import { responsiveScale } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Stack from '../primitives/Stack'
import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps

const ToolsBlock = ({
  paddingTop = responsiveScale(92),
  paddingBottom = responsiveScale(92),
}: Props) => {
  return (
    <Block
      maxWidth="mediumBlock"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Stack space={48}>
        <Box.img loading="lazy" src={ToolsImage} />
        <Box textAlign="center">
          <Box.h2 font="h2" marginBottom={24}>
            Works with the tools you already use
          </Box.h2>
          <Box
            font="large"
            color="black500"
            maxWidth="smallBlock"
            marginX="auto"
          >
            Swarmia combines data from your codebase and issue tracker. It also
            integrates with chat to speed up developer workflows.
          </Box>
        </Box>
      </Stack>
    </Block>
  )
}

export default ToolsBlock
